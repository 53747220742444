export default {
  tab_labels: {
    performance: 'Performance',
    new_restaurants: 'New Restaurants',
    customer_health: 'Customer Health',
    live_and_inactive: 'Live and Inactive',
  },

  performance_tab: {
    table_columns: {
      name: 'Name',
      sales_manager: 'Sales Manager',
      orders: 'Orders',
      sales: 'Sales',
    },
    labels: {
      sales_volume: 'Sales Volume:',
      sales_manager: 'Sales Manager',
      all: 'All',
      click_to_sort: 'Click to sort',
      no_data: 'No data',
      restaurants_count: 'Restaurants count: {{count}}',
    },
    toasts: {
      failed_to_load_data: 'Failed to load data',
    },
  },

  new_restaurants_tab: {
    table_columns: {
      name: 'Name',
      sales_manager: 'Sales Manager',
      orders: 'Orders',
      sales: 'Sales',
      days_live: 'Days Live',
      early_performance: 'Early Performance',
    },
    labels: {
      sales_manager: 'Sales Manager',
      click_to_sort: 'Click to sort',
      no_data: 'No data',
      restaurants_count: 'Restaurants count: {{count}}',
      only_with_early_performance: 'Only with early performance: ',
      early_performance_yes: '✔️ Yes',
      early_performance_no: '✗ No',
    },
    toasts: {
      failed_to_load_data: 'Failed to load data',
    },
  },

  customer_health_tab: {
    titles: {
      sales_tiers: 'Sales Tiers',
      payments_ratio: 'Payments Ratio',
    },
    labels: {
      sales_manager: 'Sales Manager',
    },
    dataset_labels: {
      negative: 'Negative ({{values}})',
      ok: 'OK ({{values}})',
      good: 'Good ({{values}})',
      excellent: 'Excellent ({{values}})',
    },

    companies_preview_modal: {
      title: 'Restaurants Preview',
      table_columns: {
        name: 'Name',
        sales_manager: 'Sales Manager',
        orders: 'Orders',
        sales: 'Sales',
        total_payments: 'Total Payments',
        payments_ratio: 'Payments Ratio',
      },
      labels: {
        close: 'Close',
        click_to_sort: 'Click to sort',
        no_data: 'No data',
      },
    },
    toasts: {
      failed_to_load_data: 'Failed to load data',
    },
  },

  live_and_inactive_tab: {
    dataset_labels: {
      live: 'Live',
      no_orders: 'No orders',
      no_conversion: 'No conversion',
    },
    labels: {
      sales_manager: 'Sales Manager',
    },
    toasts: {
      failed_to_load_data: 'Failed to load data',
    },
  },
};
