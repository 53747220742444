export default {
  containers: {
    location_info: 'Location Info',
    attributes: 'Attributes',
    links: 'Links',
  },
  labels: {
    title: 'Title',
    phone_number: 'Phone Number',
    description: 'Description',
    website: 'Website',
    address: 'Address',
    regular_hours: 'Regular Hours',
    page_urls: 'Page Urls',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    twenty_four_hours: '24hrs',
    open_24_hours: 'Open 24 Hours',
    open_time: 'Open Time',
    close_time: 'Close Time',
    url: 'URL',
    provider_type: 'Provider Type',
    provider_types: {
      merchant: 'Merchant',
      aggregator: 'Aggregator',
    },
    place_action_type: 'Type',
    is_preferred: 'Preferred',
    place_action_types: {
      appointment: 'Booking an appointment',
      online_appointment: 'Booking an online appointment',
      dining_reservation: 'Making a dining reservation',
      food_ordering: 'Ordering food for delivery and/or takeout',
      food_delivery: 'Delivery link',
      food_takeout: 'Takeout link',
      shop_online: 'Shopping, that can be delivery and/or pickup',
    },
    no_links: 'No links available for this business',
    url_menu: 'Menu link',
    url_reservations: 'Reservations link',
  },
  placeholders: {
    title: 'Title',
    phone_number: 'Phone Number',
    description: 'Description',
    website: 'Website',
    address: 'Address',
    regular_hours: 'Regular Hours',
    page_urls: 'Page Urls',
    address_line_1: 'Address Line 1',
    postal_code: 'Postal Code',
    locality: 'Locality',
    region_code: 'Region Code',
    language_code: 'Language Code',
    select_time: 'Select Time',
    url: 'URL',
  },
  buttons: {
    submit: 'Submit',
    add: 'Add',
    delete: 'Delete',
    generate_description: 'AI Generate Description',
  },
  validation: {
    required: 'This field is required',
    invalid_url: 'Invalid URL',
  },
  toasts: {

  },
};
