import {
  CButton,
  CCollapse, CFormGroup,
  CInput,
  CLabel, CNav, CNavItem, CNavLink, CTabs,
} from '@coreui/react';
import React, { useMemo, useState } from 'react';
import CIcon from '@coreui/icons-react';
import classnames from 'classnames';
import { collect } from 'collect.js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useFeedbackLabel from '../../../hooks/useFeedbackLabel';
import ArrowDownIcon from '../../../resources/assets/icons/ArrowDownIcon';
import ArrowUpIcon from '../../../resources/assets/icons/ArrowUpIcon';
import DeliveryZoneField from './DeliveryZoneField';
import ColorPicker from '../../form/ColorPicker';
import CustomButton from '../../CustomButton';
import { DELIVERY_ZONE_GEOMETRY_TYPES, DeliveryMode } from '../../../services/exports/Constants';
import DeleteCancelModal from '../../../modals/DeleteCancelModal';
import TextArea from '../../form/TextArea';
import HelperMethods from '../../../services/exports/HelperMethods';
import CurrencyInput from '../../form/CurrencyInput';
import useApiClient from '../../../hooks/useApiClient';

export default function DeliveryZone({
  data,
  deliveryMode,
  index,
  geometryRef,
  prepareGeometryData,
  updateInitialZone,
  resetChanges,
  onChange,
  onSelect,
  onDelete,
  isReadonly,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:Delivery:DeliveryZone' });

  const { DeliveryZonesManager } = useApiClient();
  const { feedback } = useFeedbackLabel();

  const [isCollapsed, setIsCollapsed] = useState(!!data?.id);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);

  const isZip = data.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.ZIP;
  const isCircle = data.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE;

  const getGeometry = () => {
    if (isZip) {
      return data?.geometry_data;
    }

    return isCircle
      ? (geometryRef?.current?.state?.circle ?? geometryRef?.current?.circle)
      : (geometryRef?.current?.state?.polygon ?? geometryRef?.current?.polygon);
  };

  const isComplete = useMemo(() => {
    const fieldsAreFilled = data?.name
      && data?.color
      && data?.min_threshold !== null
      && data?.fee !== null;

    if (data?.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE) {
      return fieldsAreFilled
        && data?.geometry_data
        && data?.geometry_data?.radius
        && data?.geometry_data?.center
        && data?.geometry_data?.center?.lat
        && data?.geometry_data?.center?.lng;
    }

    if (data?.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.ZIP) {
      return fieldsAreFilled
        && data?.geometry_data
        && collect(data?.geometry_data).every(
          (item) => typeof item === 'string'
              && (item?.length === 0 || item.length > 2),
        );
    }

    return fieldsAreFilled
      && data?.geometry_data
      && data?.geometry_data?.paths
      && collect(data?.geometry_data?.paths).every(
        (item) => item?.lat && item?.lng,
      );
  }, [data]);

  const _onChange = (field, value) => {
    setWasChanged(true);
    onChange(field, value);
  };

  const _resetChanges = () => {
    setWasChanged(false);
    resetChanges();
  };

  const toggle = () => setIsCollapsed((prev) => !prev);

  const toggleDeleteConfirmationModal = () => setShowDeleteConfirmationModal((prev) => !prev);

  const prepareData = () => ({
    ...data,
    geometry_data: prepareGeometryData(
      data?.geometry_type,
      getGeometry(),
    ),
    fee: HelperMethods.parseFloat(data.fee),
    min_threshold: HelperMethods.parseFloat(data.min_threshold),
    max_threshold: HelperMethods.parseFloat(data.max_threshold),
  });

  const save = async () => {
    setLoading(true);
    const { success, data: responseData } = await createOrUpdate();
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_save_changes', { name: data?.name }));
    }

    onChange('id', responseData?.id);
    updateInitialZone(responseData);
    setWasChanged(false);

    return toast.success(t('toasts.delivery_zone_got_updated', { name: data?.name }));
  };

  const createOrUpdate = async () => {
    if (!data?.id) {
      return DeliveryZonesManager.create(prepareData());
    }

    return DeliveryZonesManager.update(data?.id, prepareData());
  };

  const deleteZone = async () => {
    if (!showDeleteConfirmationModal) {
      return setShowDeleteConfirmationModal(true);
    }

    if (!data?.id) {
      onDelete();

      return toggleDeleteConfirmationModal();
    }

    setLoading(true);
    const { success } = await DeliveryZonesManager.delete(data?.id);
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete_delivery_zone', { name: data?.name }));
    }

    onDelete();
    toggleDeleteConfirmationModal();

    return toast.success(t('toasts.delivery_zone_got_deleted', { name: data?.name }));
  };

  const types = collect([
    {
      id: DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE,
      active: true,
      name: t('types.circle'),
    },
    {
      id: DELIVERY_ZONE_GEOMETRY_TYPES.POLYGON,
      active: deliveryMode === DeliveryMode.Internal,
      name: t('types.polygon'),
    },
    {
      id: DELIVERY_ZONE_GEOMETRY_TYPES.ZIP,
      active: deliveryMode === DeliveryMode.Internal,
      name: t('types.zip'),
    },
  ]).where('active');

  const renderDeleteConfirmationModal = useMemo(() => showDeleteConfirmationModal && (
    <DeleteCancelModal
      show={showDeleteConfirmationModal}
      title={t('modals.delete_confirmation.title', { name: data?.name })}
      onConfirm={deleteZone}
      toggleModal={toggleDeleteConfirmationModal}
    />
  ), [showDeleteConfirmationModal, data]);

  return (
    <div
      className={classnames('border px-3 py-4', className)}
      onClick={onSelect}
    >
      <div className="d-flex justify-content-between items-center cursor-pointer" onClick={toggle}>
        <div className="d-flex">
          <div className="mr-2 h-4 w-4 rounded-full" style={{ backgroundColor: data?.is_hidden ? '#b3b3b3' : data?.color }} />
          <h6 className={classnames('font-semibold mb-0', { 'text-disabled': data?.is_hidden })}>
            {data?.name}
            {' '}
            {data?.is_hidden && t('labels.hidden')}
          </h6>
        </div>
        {isCollapsed ? <ArrowDownIcon width={18} className="my-auto" /> : <ArrowUpIcon width={18} className="my-auto" />}
      </div>
      <CCollapse show={!isCollapsed}>
        <CTabs>
          <CNav variant="tabs" className="mt-2">
            {types.map((item) => (
              <CNavItem key={`delivery-zone-${index}-geometry-type-${item.id}`}>
                <CNavLink
                  active={data?.geometry_type === item.id}
                  disabled={isReadonly}
                  onClick={() => {
                    _onChange('geometry_data', null);
                    _onChange('geometry_type', item.id);
                  }}
                >
                  {item.name}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>
        </CTabs>

        <DeliveryZoneField
          label={(
            <CLabel htmlFor={`delivery-zone-${index}-name`} className="my-auto">{t('fields.name.label')}</CLabel>
          )}
          field={(
            <CInput
              id={`delivery-zone-${index}-name`}
              value={data?.name ?? ''}
              disabled={isReadonly}
              onChange={(e) => _onChange('name', e?.target?.value)}
            />
          )}
          errors={feedback?.errors?.name}
          className="mt-2"
        />
        <DeliveryZoneField
          label={(
            <CLabel htmlFor={`delivery-zone-${index}-color`} className="my-auto">{t('fields.color.label')}</CLabel>
          )}
          field={(
            <ColorPicker
              value={data?.color ?? ''}
              disabled={isReadonly}
              onChange={(value) => _onChange('color', value)}
            />
          )}
          errors={feedback?.errors?.color}
          className="mt-2"
        />
        <DeliveryZoneField
          label={(
            <CLabel htmlFor={`delivery-zone-${index}-min-threshold`} className="my-auto">{t('fields.min_threshold.label')}</CLabel>
          )}
          field={(
            <CurrencyInput
              id={`delivery-zone-${index}-min-threshold`}
              value={data?.min_threshold ?? ''}
              disabled={isReadonly}
              onChange={(value) => _onChange('min_threshold', value)}
            />
          )}
          errors={feedback?.errors?.min_threshold}
          className="mt-2"
        />
        <DeliveryZoneField
          label={(
            <CLabel htmlFor={`delivery-zone-${index}-max-threshold`} className="my-auto">{t('fields.max_threshold.label')}</CLabel>
          )}
          field={(
            <CurrencyInput
              id={`delivery-zone-${index}-max-threshold`}
              value={data?.max_threshold ?? ''}
              disabled={isReadonly}
              onChange={(value) => _onChange('max_threshold', value)}
            />
          )}
          errors={feedback?.errors?.max_threshold}
          className="mt-2"
        />
        <DeliveryZoneField
          label={(
            <CLabel htmlFor={`delivery-zone-${index}-fee`} className="my-auto">{t(`fields.fee.${deliveryMode}.label`)}</CLabel>
          )}
          field={(
            <CurrencyInput
              id={`delivery-zone-${index}-fee`}
              value={data?.fee ?? ''}
              disabled={isReadonly}
              onChange={(value) => _onChange('fee', value)}
            />
          )}
          errors={feedback?.errors?.fee}
          className="mt-2"
        />
        {data?.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.ZIP && (
          <CFormGroup className="mt-2">
            <CLabel htmlFor={`delivery-zone-${index}-zip-codes`}>{t('fields.zip_codes.label')}</CLabel>
            <TextArea
              id={`delivery-zone-${index}-zip-codes`}
              value={collect(data?.geometry_data ?? []).implode('\n')}
              disabled={isReadonly}
              onChange={(value) => _onChange('geometry_data', value.split('\n'))}
            />
          </CFormGroup>
        )}
        {!isReadonly && (
          <>
            <div className="mt-4 d-flex justify-content-between">
              <div className="d-flex items-center">
                <CIcon
                  name="cil-trash"
                  className="mr-3 my-auto cursor-pointer"
                  onClick={deleteZone}
                />
                <CIcon
                  name="cil-low-vision"
                  className="my-auto cursor-pointer"
                  onClick={() => _onChange('is_hidden', !data?.is_hidden)}
                />
              </div>
              <CustomButton
                title={t('buttons.save')}
                onClick={save}
                loading={loading}
                disabled={!isComplete}
              />
            </div>
            {wasChanged && (
              <CButton
                className="mt-2 underline w-full"
                onClick={_resetChanges}
              >
                {t('buttons.cancel_changes')}
              </CButton>
            )}
          </>
        )}
      </CCollapse>
      {renderDeleteConfirmationModal}
    </div>
  );
}
