import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CalculatorOutlined, HomeOutlined, LineChartOutlined, ShopOutlined, TabletOutlined,
} from '@ant-design/icons';
import collect from 'collect.js';
import { Badge } from 'antd';
import {
  ENVIRONMENTS, ROLES, SCREENS, SCREEN_GROUPS,
} from '../services/exports/Constants';
import useVerification from './useVerification';
import usePermissions from './usePermissions';
import TableIcon from '../resources/assets/icons/TableIcon';
import MarketingIcon from '../resources/assets/icons/MarketingIcon';

export default function useNavigationItems() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Hooks:useNavigationItems' });

  const { isComplete: isOnboardingComplete, getRemainingSteps } = useVerification();
  const { roles, hasAnyRole, isAdmin } = usePermissions();

  const { company } = useSelector((state) => state.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { badges: reduxBadges } = useSelector((state) => state.sideBar);
  const badges = {
    ...reduxBadges,
    [SCREENS.GET_STARTED]: getRemainingSteps(),
  };

  const items = [
    {
      key: SCREEN_GROUPS.ONLINE_SHOP,
      group: t('groups.online_shop'),
      icon: <HomeOutlined />,
      children: [
        {
          hidden: !company?.id || isOnboardingComplete,
          exclude_roles: [ROLES.content_manager],
          label: t('get_started.title'),
          key: SCREENS.GET_STARTED,
          badge: {
            color: 'primary',
            text: badges?.[SCREENS.GET_STARTED] > 0 ? badges?.[SCREENS.GET_STARTED] : null,
          },
        },
        {
          exclude_roles: [ROLES.content_manager],
          label: 'Home',
          key: SCREENS.DASHBOARD,
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager],
          label: t('customer_base.title'),
          hidden: !!company?.id,
          key: SCREENS.CUSTOMER_BASE,
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          exclude_roles: [ROLES.content_manager],
          label: t('new_company.title'),
          key: SCREENS.COMPANIES,
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          exclude_roles: [ROLES.content_manager],
          hidden: serviceProvider?.regional_config?.supported_delivery_providers?.length < 2 || !import.meta.env.PROD,
          label: t('external_delivery_support.title'),
          key: SCREENS.EXTERNAL_DELIVERY_SUPPORT,
        },
        {
          roles: [ROLES.reseller, ROLES.onboarding_manager],
          label: t('stripe.title'),
          key: SCREENS.STRIPE,
          badge: {
            color: 'warning',
            text: badges?.[SCREENS.STRIPE] > 0 ? badges?.[SCREENS.STRIPE] : null,
          },
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          exclude_roles: [ROLES.content_manager],
          hidden: !serviceProvider?.is_master,
          label: t('going_live_overview.title'),
          key: SCREENS.GOING_LIVE_OVERVIEW,
          badge: {
            color: 'dark',
            text: badges?.[SCREENS.GOING_LIVE_OVERVIEW] > 0 ? badges?.[SCREENS.GOING_LIVE_OVERVIEW] : null,
          },
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          exclude_roles: [ROLES.content_manager],
          hidden: !serviceProvider?.is_master,
          label: t('going_live.title'),
          children: [
            {
              label: t('going_live.children.setup_package_fulfillments'),
              key: SCREENS.GOING_LIVE_SETUP_PACKAGE_FULFILLMENTS,
              badge: {
                color: 'info',
                text: badges?.[SCREENS.GOING_LIVE_SETUP_PACKAGE_FULFILLMENTS] > 0 ? badges?.[SCREENS.GOING_LIVE_SETUP_PACKAGE_FULFILLMENTS] : null,
              },
            },
            {
              hidden: !company?.id,
              label: t('going_live.children.flyers'),
              key: SCREENS.MARKETING_FLYERS,
            },
          ],
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          exclude_roles: [ROLES.content_manager],
          label: t('franchises.title'),
          key: SCREENS.FRANCHISES,
        },
        {
          roles: [ROLES.reseller],
          exclude_roles: [ROLES.content_manager],
          label: t('subscriptions.title'),
          children: [
            {
              label: t('subscriptions.children.subscriptions'),
              key: SCREENS.SUBSCRIPTIONS,
            },
            {
              label: t('subscriptions.children.products'),
              key: SCREENS.SUBSCRIPTION_PRODUCTS,
            },
          ],
        },
        {
          exclude_roles: [ROLES.content_manager],
          label: t('sales.title'),
          children: [
            {
              label: t('sales.children.orders'),
              key: SCREENS.SALES_ORDERS,
            },
            {
              hidden: !company?.id,
              label: t('sales.children.receipts'),
              key: SCREENS.SALES_RECEIPTS,
            },
            {
              roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
              label: t('sales.children.external_deliveries'),
              key: SCREENS.SALES_EXTERNAL_DELIVERIES,
            },
          ],
        },
        {
          exclude_roles: [ROLES.content_manager],
          label: t('billing.title'),
          children: [
            {
              hidden: !company?.id,
              roles: [ROLES.reseller, ROLES.onboarding_manager],
              label: t('billing.children.monthly_statements'),
              key: SCREENS.BILLING_MONTHLY_STATEMENTS,
            },
            {
              exclude_roles: [ROLES.content_manager],
              hidden: !company?.id ? roles.includes([ROLES.sales_manager, ROLES.reseller, ROLES.onboarding_manager]) : false,
              label: t('billing.children.payouts'),
              key: SCREENS.PAYOUTS,
            },
            {
              hidden: !company?.id,
              label: t('billing.children.invoices'),
              key: SCREENS.BILLING_INVOICES,
            },
            {
              hidden: !company?.id,
              label: t('billing.children.subscription'),
              key: SCREENS.BILLING_SUBSCRIPTION,
            },
            {
              label: t('billing.children.internal_invoice_items'),
              key: SCREENS.BILLING_INTERNAL_INVOICE_ITEMS,
            },
            {
              roles: [ROLES.reseller],
              label: t('billing.children.income_reports'),
              key: SCREENS.BILLING_INCOME_REPORTS,
            },
          ],
        },
        {
          hidden: !company?.id,
          label: t('menu.title'),
          children: [
            {
              label: t('menu.children.manage_menus'),
              key: SCREENS.MENU,
            },
            {
              label: t('menu.children.modifiers'),
              key: SCREENS.MODIFIERS,
            },
            {
              label: t('menu.children.smart_pricing'),
              key: SCREENS.SMART_PRICING,
            },
            {
              label: t('menu.children.upsell_items'),
              key: SCREENS.RECOMMENDED_PRODUCTS,
            },
            {
              label: t('menu.children.sold_out_items'),
              key: SCREENS.SOLD_OUT_ITEMS,
            },
            {
              label: t('menu.children.import'),
              key: SCREENS.MENU_IMPORT,
            },
          ],
        },
        // {
        //   _tag: 'CSidebarNavItem',
        //   label: t('google_reviews.title'),
        //   key: SCREENS.GOOGLE_REVIEWS,
        //   // icon: 'cib-google',
        // },
        {
          exclude_roles: [ROLES.content_manager],
          hidden: !company?.id,
          label: t('my_store.title'),
          children: [
            {
              hidden: !company?.id,
              roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
              label: t('my_store.children.settings'),
              key: SCREENS.MY_STORE_SETTINGS,
            },
            {
              hidden: !company?.id,
              label: t('my_store.children.gallery'),
              key: SCREENS.MY_STORE_GALLERY,
            },
            {
              label: t('my_store.children.business_information'),
              key: SCREENS.MY_STORE_BUSINESS_INFO,
            },
            {
              label: t('my_store.children.personal_information'),
              key: SCREENS.MY_STORE_PERSONAL_INFO,
            },
            {
              label: t('my_store.children.bank_info'),
              key: SCREENS.MY_STORE_BANK_INFO,
            },
            {
              label: t('my_store.children.tax_info'),
              key: SCREENS.MY_STORE_TAX_INFO,
            },
            {
              label: t('my_store.children.shipping_info'),
              key: SCREENS.MY_STORE_SHIPPING_INFO,
            },
            {
              roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
              label: t('my_store.children.google_profile'),
              key: SCREENS.MY_STORE_GOOGLE_PROFILE,
            },
          ],
        },
        {
          exclude_roles: [ROLES.content_manager],
          hidden: !company?.id,
          label: t('opening_hours.title'),
          children: [
            {
              label: t('opening_hours.children.opening_hours'),
              key: SCREENS.OPENING_HOURS,
            },
            {
              hidden: !company?.id,
              label: t('opening_hours.children.location'),
              key: SCREENS.LOCATIONS,
            },
          ],
        },
        {
          exclude_roles: [ROLES.content_manager],
          hidden: !company?.id,
          label: t('order_settings.title'),
          children: [
            {
              label: t('order_settings.children.takeout'),
              key: SCREENS.TAKEOUT,
            },
            {
              roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
              label: t('order_settings.children.payment_methods'),
              key: SCREENS.PAYMENT_METHODS,
            },
            {
              label: t('order_settings.children.tips'),
              key: SCREENS.TIP_SETTINGS,
            },
            {
              label: t('order_settings.children.receipt'),
              key: SCREENS.RECEIPT_SETTINGS,
            },
            {
              label: t('order_settings.children.staff_credentials'),
              key: SCREENS.STAFF_CREDENTIALS,
            },
            {
              label: t('order_settings.children.integrations'),
              key: SCREENS.Integrations,
            },
            {
              label: t('order_settings.children.terminal'),
              key: SCREENS.TERMINAL,
            },
          ],
        },
        {
          exclude_roles: [ROLES.content_manager],
          hidden: !company?.id,
          label: t('loyalty_tablet.title'),
          children: [
            {
              label: t('loyalty_tablet.children.dashboard'),
              key: SCREENS.LOYALTY_DASHBOARD,
            },
            {
              roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
              label: t('loyalty_tablet.children.settings'),
              key: SCREENS.LOYALTY_TABLET,
            },
            {
              label: t('loyalty_tablet.children.credentials'),
              key: SCREENS.LOYALTY_TABLET_CREDENTIALS,
            },
          ],
        },
        {
          exclude_roles: [ROLES.content_manager],
          hidden: !serviceProvider?.has_merchant_contracts,
          label: t('contract.title'),
          children: [
            {
              hidden: !company?.id,
              exclude_roles: [ROLES.content_manager],
              label: t('contract.children.agreement'),
              key: SCREENS.CONTRACT_AGREEMENT,
            },
            {
              roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
              label: t('contract.children.template'),
              key: SCREENS.CONTRACT_TEMPLATE,
            },
          ],
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager],
          hidden: !!company?.id,
          label: t('team.title'),
          children: [
            {
              roles: [ROLES.reseller],
              label: t('team.children.members'),
              key: SCREENS.TEAM_MEMBERS,
            },
            {
              roles: [ROLES.reseller],
              label: t('team.children.reports'),
              key: SCREENS.TEAM_REPORTS,
            },
            {
              roles: [ROLES.sales_manager],
              label: t('team.children.signed_live'),
              key: SCREENS.SALES_MANAGER_REPORTS,
            },
          ],
        },
      ],
    },
    {
      key: SCREEN_GROUPS.MARKETING,
      group: t('groups.marketing'),
      icon: <MarketingIcon />,
      hidden: !(isAdmin || company?.id),
      children: [
        {
          label: t('marketing.children.overview'),
          key: SCREENS.MARKETING_OVERVIEW,
        },
        {
          label: t('marketing.children.followers'),
          key: SCREENS.MARKETING_FOLLOWERS,
          hidden: !company?.id,
        },
        {
          label: t('marketing.children.email_messages'),
          key: SCREENS.MARKETING_EMAIL_MESSAGES,
          hidden: !company?.id,
        },
        {
          label: t('marketing.children.promo_codes'),
          key: SCREENS.MARKETING_PROMO_CODES,
          hidden: !company?.id,
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          label: t('marketing.children.google_review_program'),
          key: SCREENS.MARKETING_GOOGLE_REVIEW_PROGRAM,
          hidden: !company?.id,
        },
        {
          label: t('marketing.children.google_business'),
          key: SCREENS.MARKETING_GOOGLE_BUSINESS,
          hidden: !company?.id,
        },
        {
          label: t('marketing.children.loyalty_program'),
          key: SCREENS.MARKETING_LOYALTY_PROGRAM,
          hidden: !company?.id,
        },
        {
          roles: [ROLES.reseller, ROLES.sales_manager, ROLES.onboarding_manager],
          label: t('marketing.children.flyers'),
          key: SCREENS.MARKETING_FLYERS,
          hidden: !company?.id,
        },
      ],
    },
    {
      key: SCREEN_GROUPS.RESERVATIONS,
      group: t('groups.reservations'),
      icon: <TableIcon />,
      exclude_roles: [ROLES.content_manager],
      hidden: !company?.id,
      children: [
        {
          label: t('reservations.children.overview'),
          key: SCREENS.RESERVATIONS_OVERVIEW,
          badge: {
            color: 'info',
            text: badges?.[SCREENS.RESERVATIONS] > 0 ? badges?.[SCREENS.RESERVATIONS] : null,
          },
        },
        {
          label: t('reservations.children.booking_availability'),
          key: SCREENS.RESERVATIONS_BOOKING_AVAILABILITY,
        },
        {
          label: t('reservations.children.settings'),
          key: SCREENS.RESERVATIONS_SETTINGS,
        },
      ],
    },
    {
      key: SCREEN_GROUPS.STOREFRONT,
      group: t('groups.storefront'),
      icon: <ShopOutlined />,
      exclude_roles: [ROLES.content_manager],
      hidden: !company?.id,
      children: [
        {
          label: t('storefront.title'),
          key: SCREENS.STOREFRONT,
        },
      ],
    },
    {
      key: SCREEN_GROUPS.ORDERS_PORTAL,
      group: t('groups.orders_portal'),
      icon: <TabletOutlined />,
      exclude_roles: [ROLES.content_manager],
      hidden: !company?.id,
    },
    {
      key: SCREEN_GROUPS.ROI,
      group: t('groups.roi'),
      icon: <CalculatorOutlined />,
      hidden: import.meta.env.MODE === ENVIRONMENTS.DEV || !serviceProvider?.is_master,
    },
    {
      key: SCREEN_GROUPS.SEO,
      roles: [ROLES.admin],
      icon: <LineChartOutlined />,
      group: t('groups.seo'),
      children: [
        {
          label: t('seo.children.overview'),
          key: SCREENS.SEO_OVERVIEW,
        },
      ],
    },
  ];

  function filterItems(items) {
    return collect(items)
      .filter((item) => {
        const { roles, exclude_roles, hidden } = item;
        const hasRole = roles ? hasAnyRole(roles) : true;
        const excludeRole = exclude_roles ? (!isAdmin && hasAnyRole(exclude_roles)) : false;
        return !hidden && !excludeRole && hasRole;
      })
      .map((item) => {
        if (item.children) {
          return {
            ...item,
            children: filterItems(item.children),
          };
        }

        return {
          ...item,
          label: item?.badge
            ? (
              <Link to={item.key} className="tw-block hover:tw-no-underline">
                {item.label}
                {
                  item.badge && (
                    <Badge
                      count={item.badge.text}
                      color={item.badge.color}
                      overflowCount={item.badge.overflowCount ?? 9999999}
                      className="tw-ml-mini"
                    />
                  )
                }
              </Link>
            )
            : <Link to={item.key} className="tw-block hover:tw-no-underline">{item.label}</Link>,
        };
      }).toArray();
  }

  return filterItems(items);
}
