export default {
  toasts: {
    failed_to_create_website: 'Erstellung der Website fehlgeschlagen',
  },
  steps: {
    information: 'Informationen',
    template: 'Vorlage',
  },
  buttons: {
    next: 'Weiter',
    previous: 'Zurück',
    create_website: 'Website erstellen',
  },
};
