export default {
  tab_labels: {
    performance: 'Leistung',
    new_restaurants: 'Neue Restaurants',
    customer_health: 'Kunden Gesundheit',
    live_and_inactive: 'Live und Inaktiv',
  },

  performance_tab: {
    table_columns: {
      name: 'Name',
      sales_manager: 'Vertriebsleiter',
      orders: 'Bestellungen',
      sales: 'Vertrieb',
      total_payments: 'Gesamte Zahlungen',
      payments_ratio: 'Zahlungsverhältnis',
    },
    labels: {
      sales_volume: 'Umsatzvolumen:',
      sales_manager: 'Vertriebsleiter',
      all: 'Alle',
      click_to_sort: 'Klicken Sie zum Sortieren',
      no_data: 'Keine Daten',
      restaurants_count: 'Anzahl der Restaurants: {{count}}',
    },
    toasts: {
      failed_to_load_data: 'Daten konnten nicht geladen werden',
    },
  },

  new_restaurants_tab: {
    table_columns: {
      name: 'Name',
      sales_manager: 'Vertriebsleiter',
      orders: 'Bestellungen',
      sales: 'Vertrieb',
      days_live: 'Tage Live',
      early_performance: 'Frühe Leistung',
    },
    labels: {
      sales_manager: 'Vertriebsleiter',
      click_to_sort: 'Klicken Sie zum Sortieren',
      no_data: 'Keine Daten',
      restaurants_count: 'Anzahl der Restaurants: {{count}}',
      only_with_early_performance: 'Nur mit früher Leistung: ',
      early_performance_yes: '✔️ Ja',
      early_performance_no: '✗ Nein',
    },
    toasts: {
      failed_to_load_data: 'Daten konnten nicht geladen werden',
    },
  },

  customer_health_tab: {
    titles: {
      sales_tiers: 'Vertriebsklassen',
      payments_ratio: 'Zahlungsverhältnis',
    },
    labels: {
      sales_manager: 'Vertriebsleiter',
    },
    dataset_labels: {
      negative: 'Negativ ({{values}})',
      ok: 'OK ({{values}})',
      good: 'Gut ({{values}})',
      excellent: 'Ausgezeichnet ({{values}})',
    },

    companies_preview_modal: {
      title: 'Restaurants Vorschau',
      table_columns: {
        name: 'Name',
        sales_manager: 'Vertriebsleiter',
        orders: 'Bestellungen',
        sales: 'Vertrieb',
      },
      labels: {
        close: 'Schließen',
        click_to_sort: 'Klicken Sie zum Sortieren',
        no_data: 'Keine Daten',
      },
    },
    toasts: {
      failed_to_load_data: 'Daten konnten nicht geladen werden',
    },
  },

  live_and_inactive_tab: {
    dataset_labels: {
      live: 'Live',
      no_orders: 'Keine Bestellungen',
      no_conversion: 'Keine Konvertierung',
    },
    labels: {
      sales_manager: 'Vertriebsleiter',
    },
    toasts: {
      failed_to_load_data: 'Daten konnten nicht geladen werden',
    },
  },
};
