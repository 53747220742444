export default {
  title: 'Google business',
  sections: {
    google_reviews: 'Google reviews',
  },
  form: {
    has_google_review_auto_replies: {
      label: 'AI auto replies',
    },
  },
  modal_publish: {
    title: 'Are you sure you want to publish to Google?',
    buttons: {
      yes: 'Yes',
      cancel: 'Cancel',
    },
  },
  popconfirm: {
    title: 'Are you sure?',
    description: 'All local changes will be overwritten',
  },
  buttons: {
    login_with_google: 'Login with Google',
    sync_from_google: 'Get Fresh Version',
    publish_to_google: 'Publish to Google',
    previous: 'Previous',
    next: 'Next',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    failed_to_fetch_reviews: 'Failed to fetch reviews',
    failed_to_load_google_business: 'Failed to load google business',
    failed_to_publish_to_google_business: 'Failed to publish to google business',
    failed_to_fetch_info: 'Failed to get google business fresh version',
    success_publish_to_google_business: 'Successfully published to Google Business',
    success_sync_from_google: 'Successfully synced from Google Business',
  },
};
