import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';

export const filter_day = 'day';
export const filter_week = 'week';
export const filter_month = 'month';
export const filter_total = null;

export const options = [
  filter_day,
  filter_week,
  filter_month,
  filter_total,
];

export default function PeriodFilter({ activeOption, onChange }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Dashboard:PeriodFilter' });

  function onChangeHandler(newTab) {
    ReduxHooks.dispatch(actionCreators.activeTab.set(newTab));
    onChange(newTab);
  }

  return (
    <Radio.Group onChange={(e) => onChangeHandler(e.target.value)} value={activeOption} defaultValue={activeOption}>
      {options.map((value) => (
        <Radio.Button
          value={value}
          key={`period-filter-${value}`}
        >
          {t(`filters.${value}`)}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}
