import {
  FULFILLMENT_STATUSES, IntegrationService,
  ORDER_METHODS, ORDER_PAYMENT_STATUSES,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../../services/exports/Constants';
import { action_create_dummy_order, action_launch_orders_portal_app } from '../../../../../../views/sales/orders';

export default {
  title: 'Orders',
  buttons: {
    actions: 'Actions',
    [action_create_dummy_order]: 'Create test order',
    [action_launch_orders_portal_app]: 'Launch orders portal',
    show_order_details: 'Show order details',
    track_order: 'Track order',
  },
  form: {
    customer_details: {
      placeholder: 'Enter customer name or phone number',
    },
    utm_source: {
      placeholder: 'Enter utm source',
    },
    utm_medium: {
      placeholder: 'Enter utm medium',
    },
    utm_campaign: {
      placeholder: 'Enter utm campaign',
    },
    utm_advertisement_id: {
      placeholder: 'Enter utm advertisement id',
    },
  },
  labels: {
    no_orders: 'No orders yet',
    total_orders: 'Total orders',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ORDER_METHODS.room_service]: {
      [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
      [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
    },
  },
  payment_statuses: {
    [ORDER_PAYMENT_STATUSES.pending]: 'Pending',
    [ORDER_PAYMENT_STATUSES.paid]: 'Paid',
    [ORDER_PAYMENT_STATUSES.partially_refunded]: 'Partially refunded',
    [ORDER_PAYMENT_STATUSES.refunded]: 'Refunded',
  },
  fulfillment_statuses: {
    default: {
      [FULFILLMENT_STATUSES.created]: 'Pending approval',
      [FULFILLMENT_STATUSES.reviewed]: 'Pending approval',
      [FULFILLMENT_STATUSES.accepted]: 'In work',
      [FULFILLMENT_STATUSES.preparing]: 'In work',
      [FULFILLMENT_STATUSES.ready]: 'In work',
      [FULFILLMENT_STATUSES.on_delivery]: 'In work',
      [FULFILLMENT_STATUSES.picked]: 'Completed',
      [FULFILLMENT_STATUSES.rejected]: 'Rejected',
      null: 'Unknown',
      undefined: 'Unknown',
    },
  },
  tags: {
    dummy: 'TEST',
    pickup: 'Pickup',
    internal_delivery: 'Internal Delivery',
    external_delivery: 'External delivery',
    room_service: 'Room service',
    table_service: 'Table service',
    scheduled: 'Scheduled',
    reward: 'Reward',
    mobile: 'Mobile',
    cash: 'Cash',
    rejected_automatically: 'Auto rejected',
    rejected_manually: 'Manually rejected',
    [IntegrationService.WinOrder]: 'WINORDER',
    [IntegrationService.ExpertOrder]: 'EXPERT ORDER',
    [IntegrationService.Sides]: 'SIDES',
  },
  fields: {
    id: 'ID',
    company: 'Restaurant',
    tags: 'Tags',
    order_placed: 'Order placed',
    customer: 'Customer',
    items: 'Items',
    method: 'Method',
    payment_status: 'Payment status',
    fulfillment_status: 'Status',
    total: 'Total',
    utm_source: 'UTM Source',
    utm_medium: 'UTM Medium',
    utm_campaign: 'UTM campaign',
    utm_advertisement_id: 'UTM Advertisement Id',
  },
  metrics: {
    total_orders: 'Total orders',
    total_sales: 'Total sales',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_create_dummy_order: 'Failed to create test order',
    dummy_order_created: 'Test order got created',
    failed_to_launch_orders_portal_app: 'Failed to launch orders portal',
  },
};
