import ApiResourceManager from '../ApiResourceManager';

export default class CustomerBaseStatsManager extends ApiResourceManager {
  getPerformanceStats = (data) => this.request({
    method: 'POST',
    url: '/customer-base/performance-stats',
    data,
  });

  getSalesTiersStats = (params) => this.request({
    method: 'GET',
    url: '/customer-base/sales-tiers-stats',
    params,
  });

  getPaymentsRatioTiersStats = (params) => this.request({
    method: 'GET',
    url: '/customer-base/payments-ratio-tiers-stats',
    params,
  });

  getLiveAndInactiveStats = (params) => this.request({
    method: 'GET',
    url: '/customer-base/live-and-inactive-stats',
    params,
  });
}
