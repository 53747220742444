export default {
  titles: {
    disable: 'Bist du sicher, dass du Live-Bestellungen deaktivieren möchtest?',
    enable: 'Bist du sicher, dass du Live-Bestellungen aktivieren möchtest?',
  },
  labels: {
    successful_launch: 'Um einen erfolgreichen Start zu gewährleisten, überprüfe bitte die folgenden offenen Punkte. Das Überprüfen dieser Informationen hilft, mögliche Probleme nach dem Start des Restaurants zu vermeiden.',
    check_errors: 'Überprüfe die Fehler, um einen guten Start des Restaurants sicherzustellen.',
    checkbox_confirmation: 'Ich bestätige, dass ich die Fehler überprüft habe und der Aktivierung dieses Restaurants zustimme.',
  },
  buttons: {
    check_error: 'Fehler überprüfen',
    confirm_and_activate: 'Bestätigen und Aktivieren',
    cancel: 'Abbrechen',
    yes: 'Ja',
    no: 'Nein',
  },
  errors: {
    name: {
      title: 'Keine arabischen, chinesischen usw. Zeichen im Shopnamen',
      description: 'Der Online-Shopname enthält keine englischen (lateinischen) Zeichen.',
    },
    logo_url: {
      title: 'Logo',
      description: 'Das Logo wurde in den Online-Shop hochgeladen.',
    },
    pictures: {
      title: 'Galeriebild',
      description: '≥ 1 Galeriebild ist im Online-Shop verfügbar.',
    },
    menu_uploaded: {
      title: 'Menü hochgeladen',
      description: '≥ 1 Menü ist verfügbar.',
    },
    opening_hours: {
      title: 'Öffnungszeiten',
      description: '≥ 1 Öffnungszeit wurde hinzugefügt.',
    },
    address: {
      title: 'Restaurant-Adresse',
      description: 'Straße, Stadt, PLZ eingetragen.',
    },
    upsell_items: {
      title: 'Upsell-Artikel',
      description: '≥ 3 Upsell-Artikel wurden ausgewählt.',
    },
    master_promo_code: {
      title: 'Master-Promo-Code',
      description: 'Master-Promo-Code ist verfügbar.',
    },
    flyers_ordered: {
      title: 'Flyer bestellt',
      description: '≥ 1 Flyer-Paket wurde bestellt.',
    },
    test_orders: {
      title: 'Testbestellung gesendet',
      description: '≥ 1 Testbestellung wurde durchgeführt.',
    },
    stripe_billing: {
      title: 'Stripe-Konto',
      description: 'Stripe-Konto verbunden.',
    },
    storefront: {
      title: 'Storefront erstellt und Domain verbunden',
      description: 'Nur wenn das Restaurant unsere Website nutzen möchte.',
    },
    google_business: {
      title: 'Google Business',
      description: 'Google Business verbunden',
    },
  },
};
