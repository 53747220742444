export default {
  title: 'Google business',
  sections: {
    google_reviews: 'Google-Bewertungen',
  },
  form: {
    has_google_review_auto_replies: {
      label: 'AI-Autoantworten',
    },
  },
  modal_publish: {
    title: 'Bist du sicher, dass du bei Google veröffentlichen möchtest?',
    buttons: {
      yes: 'Ja',
      cancel: 'Abbrechen',
    },
  },
  popconfirm: {
    title: 'Bist du sicher?',
    description: 'Alle lokalen Änderungen werden überschrieben.',
  },
  buttons: {
    login_with_google: 'Anmeldung mit Google',
    sync_from_google: 'Hole die neueste Version',
    publish_to_google: 'Bei Google veröffentlichen',
    previous: 'Vorherige',
    next: 'Next',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    failed_to_fetch_reviews: 'Abrufen von Bewertungen fehlgeschlagen',
    failed_to_load_google_business: 'Google Business konnte nicht geladen werden',
    failed_to_publish_to_google_business: 'Veröffentlichung auf Google Business fehlgeschlagen',
    failed_to_fetch_info: 'Fehler beim Abrufen der neuesten Google-Business-Version',
    success_publish_to_google_business: 'Erfolgreich auf Google Business veröffentlicht',
    success_sync_from_google: 'Erfolgreich von Google Business synchronisiert',
  },
};
