import { filter_day, filter_month, filter_week } from '../../../../../views/marketing/overview/PeriodFilter';

export default {
  filters: {
    [filter_day]: 'Tag',
    [filter_week]: 'Woche',
    [filter_month]: 'Monat',
    null: 'Total',
  },
};
