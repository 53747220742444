export default {
  toasts: {
    failed_to_delete_website: 'Failed to delete website',
    website_deleted: 'Website got deleted',
  },
  delete_model: {
    title: 'Are you sure you want to delete the website?',
    confirm: 'Delete',
    cancel: 'Cancel',
  },
  buttons: {
    open_editor: 'Open editor',
    delete_site: 'Delete',
  },
};
