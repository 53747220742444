import { ValidationStatus } from '../../../../../../../views/marketing/followers/import';

export default {
  status: {
    [ValidationStatus.Valid]: {
      title: 'Ihr Import ist fast fertig!',
      description: 'Klicken Sie auf die Schaltfläche Fortfahren, um den Import abzuschließen.',
    },
    [ValidationStatus.HasInvalidContent]: {
      title: 'Einige Daten in Ihrer Datei fehlen oder haben ein falsches Format',
      description: 'Bitte beheben Sie die folgenden Fehler und laden Sie die Datei erneut hoch oder ignorieren Sie ungültige Zeilen/Zellen',
    },
    [ValidationStatus.Invalid]: {
      title: 'Hochgeladene Datei kann nicht verarbeitet werden',
      description: 'Bitte beheben Sie die folgenden Fehler und versuchen Sie es erneut',
    },
  },
  sections: {
    heading_validation: {
      title: 'Nicht übereinstimmende Spaltennamen/Ordnung entdeckt:',
      rules: {
        Exact: 'Spalte #{{column}} muss vorgelegt und genau benannt werden <strong>{{expected}}</strong>',
      },
    },
    content_validation: {
      title: 'Folgende Fehler wurden festgestellt:',
      labels: {
        row: 'Zeile <strong>#{{row}}:</strong>',
        imported_rows_one: '{{count}} Zeile wird importiert',
        imported_rows_other: '{{count}} Zeilen wird importiert',
        ignored_rows_one: '{{count}} Zeile wird ignoriert',
        ignored_rows_other: '{{count}} Zeilen wird ignoriert',
      },
      fields: {
        email: 'Email',
        phone_number: 'Rufnummer',
        first_name: 'Vornamen',
        last_name: 'Nachnamee',
        birthday: 'Geburtstag',
      },
      rules: {
        Required: 'Das Feld {{field}} ist erforderlich. Zeile wird ignoriert',
        Email: 'Das Feld {{field}} muss eine gültige E-Mail-Adresse sein. Zeile wird ignoriert',
        Phone: 'Das Feld {{field}} muss eine Telefonnummer im internationalen Format sein. Feld wird ignoriert',
        Date: 'Das Feld {{field}} muss ein gültiges Datum sein. Feld wird ignoriert',
      },
    },
  },
  buttons: {
    back_to_upload: 'Zurück zu upload',
    proceed: 'Weiter',
  },
  toasts: {
    error: 'Etwas ist schief gelaufen, bitte kontaktieren Sie den Support',
  },
};
