export default {
  title: 'Opening hours',
  headers: {
    edit_time: 'Edit time',
    create_time: 'Create time',
  },
  labels: {
    select_all: 'Select all',
  },
  fields: {
    selected_days: {
      label: 'Selected days',
    },
    opening_time: {
      label: 'Opening time',
    },
    closing_time: {
      label: 'Closing time',
    },
  },
  columns: {
    days: 'Days',
    time: 'Time',
  },
  buttons: {
    add_opening_time: 'Add opening time',
    add: 'Add',
    cancel: 'Cancel',
    save: 'Save',
    close: 'Close',
    delete: 'Delete',
    sync_from_online_shop: 'Sync from Online Shop',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservations',
    storefront: 'Storefront',
  },
  feedback: {
    missing_info: 'Missing information',
  },
  toasts: {
    saving_success: 'Successfully saved',
    saving_error: 'Failed to save. Please review your information and try again.',
    sync_success: 'Successfully synced',
    sync_error: 'Failed to sync opening hours from online shop. Please try again.',
    hours_required: 'Opening hours are required. Edit existing hours instead',
  },
};
