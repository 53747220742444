import Authentication from './authentication';
import Billing from './billing';
import Companies from './companies';
import Contract from './contract';
import CustomerBase from './customer-base';
import Dashboard from './dashboard';
import ExternalDeliverySupport from './external-delivery-support';
import Franchises from './franchises';
import GettingStarted from './getting-started';
import GoingLive from './going-live';
import GoingLiveOverview from './going-live-overview';
import LoyaltyTablet from './loyalty-tablet';
import Marketing from './marketing';
import Menu from './menu';
import MyStore from './my-store';
import OpeningHours from './opening-hours/index';
import OrderSettings from './order-settings';
import Profile from './profile';
import Reservations from './reservations/index';
import Sales from './sales';
import Storefront from './storefront';
import Stripe from './stripe';
import Subscriptions from './subscriptions';
import Team from './team';
import Seo from './seo';

export default {
  Authentication,
  Billing,
  Companies,
  Contract,
  CustomerBase,
  Dashboard,
  ExternalDeliverySupport,
  Franchises,
  GettingStarted,
  GoingLive,
  GoingLiveOverview,
  LoyaltyTablet,
  Marketing,
  Menu,
  MyStore,
  OpeningHours,
  OrderSettings,
  Profile,
  Reservations,
  Sales,
  Storefront,
  Stripe,
  Subscriptions,
  Team,
  Seo,
};
