import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import {
  Button, DatePicker, Dropdown, Radio, Space,
} from 'antd';
import { CalendarOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import useMoment from '../../hooks/useMoment';

const { RangePicker } = DatePicker;

export const variant_dropdown = 'dropdown';
export const variant_radio_group = 'radio_group';

export const filter_today = 'today';
export const filter_yesterday = 'yesterday';
export const filter_this_week = 'this_week';
export const filter_last_week = 'last_week';
export const filter_this_month = 'this_month';
export const filter_last_month = 'last_month';
export const filter_all = 'all';

export const filterFactory = (filter) => ({
  [filter_today]: {
    start_date: moment().startOf('day').utc().format(),
    end_date: moment().endOf('day').utc().format(),
  },
  [filter_yesterday]: {
    start_date: moment().subtract(1, 'day').utc().startOf('day')
      .format(),
    end_date: moment().subtract(1, 'day').utc().endOf('day')
      .format(),
  },
  [filter_this_week]: {
    start_date: moment().startOf('week').utc().format(),
    end_date: moment().endOf('week').utc().format(),
  },
  [filter_last_week]: {
    start_date: moment().subtract(1, 'week').utc().startOf('week')
      .format(),
    end_date: moment().subtract(1, 'week').utc().endOf('week')
      .format(),
  },
  [filter_this_month]: {
    start_date: moment().startOf('month').utc().format(),
    end_date: moment().endOf('month').utc().format(),
  },
  [filter_last_month]: {
    start_date: moment().subtract(1, 'month').utc().startOf('month')
      .format(),
    end_date: moment().subtract(1, 'month').utc().endOf('month')
      .format(),
  },
  [filter_all]: {
    start_date: null,
    end_date: null,
  },
})[filter];

const _filters = [
  filter_today,
  filter_yesterday,
  filter_this_week,
  filter_last_week,
  filter_this_month,
  filter_last_month,
  filter_all,
];

export default function DateRangePicker({
  onChange,
  variant = variant_radio_group,
  defaultFilter = filter_this_month,
  customFilters = [],
  excludeFilters = [],
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Filters:DateRangePicker' });
  const moment = useMoment();

  const filters = useMemo(
    () => collect(_filters)
      .map(
        (item) => ({ id: item, name: t(`filters.${item}`), ...filterFactory(item) }),
      )
      .merge(customFilters)
      .whereNotIn('id', excludeFilters),
    [customFilters, excludeFilters, t],
  );

  const [selectedFilter, setSelectedFilter] = useState(filters.firstWhere('id', defaultFilter));
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  function toggleCustomFilter() {
    setShowDateRangePicker(false);
    setDateRange(null);
  }

  useEffect(() => {
    if (dateRange) {
      onChange({
        start_date: moment(dateRange[0].format()).startOf('day').format(),
        end_date: moment(dateRange[1].format()).endOf('day').format(),
      });

      return;
    }

    onChange(selectedFilter);
  }, [selectedFilter, dateRange]);

  const renderVariant = ({
    [variant_dropdown]: () => (
      <Dropdown
        menu={{
          items: filters.map((filter) => ({ key: filter.id, label: filter.name })),
          selectable: true,
          selectedKeys: selectedFilter ? [selectedFilter.id] : null,
          onSelect: (e) => setSelectedFilter(filters.firstWhere('id', e.key)),
        }}
        className="tw-mr-mini"
      >
        <Button size="large">
          <Space>
            {selectedFilter?.name ?? 'Select period'}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ),
    [variant_radio_group]: () => (
      <Radio.Group
        value={selectedFilter?.id}
        onChange={(e) => setSelectedFilter(filters.firstWhere('id', e.target.value))}
        size="large"
        className="tw-mr-mini"
      >
        {filters.map((filter) => (
          <Radio.Button key={filter.id} value={filter.id}>{filter.name}</Radio.Button>
        ))}
      </Radio.Group>
    ),
  })[variant];

  return (
    <div className="tw-flex tw-items-center">
      {!showDateRangePicker && (
        <>
          {renderVariant()}
          <Button
            size="large"
            className="tw-mr-mini"
            icon={<CalendarOutlined />}
            onClick={() => setShowDateRangePicker(true)}
          />
        </>
      )}

      {showDateRangePicker && (
        <>
          <RangePicker
            className="tw-mr-mini"
            format="DD.MM.YYYY"
            value={dateRange}
            onChange={setDateRange}
            icon={<CalendarOutlined />}
            size="large"
            defaultOpen
          />
          <Button
            size="large"
            icon={<CloseOutlined />}
            onClick={toggleCustomFilter}
          />
        </>
      )}
    </div>
  );
}
