export default {
  toasts: {
    failed_to_create_website: 'Failed to create website',
  },
  steps: {
    information: 'Information',
    template: 'Template',
  },
  buttons: {
    next: 'Next',
    previous: 'Previous',
    create_website: 'Create Website',
  },
};
