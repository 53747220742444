export default {
  titles: {
    disable: 'Are you sure you want to disable live orders?',
    enable: 'Are you sure you want to turn ON live orders?',
  },
  labels: {
    successful_launch: 'To ensure a succesful launch, please review and address the following open points. Verifying \n'
        + 'this information will help avoid potential issues once the restaurant is live.',
    check_errors: 'Check the errors to ensure a good launch of the restaurant.',
    checkbox_confirmation: 'I confirm that I have reviewed the errors and consent to the activation of this restaurant.',
  },
  buttons: {
    check_error: 'Check Error',
    confirm_and_activate: 'Confirm and Activate',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
  },
  errors: {
    name: {
      title: 'No arabic, chinese etc. characters in shop name',
      description: 'Online shop name includes non english (latin characters)',
    },
    logo_url: {
      title: 'Logo',
      description: 'Logo has been uploaded to online shop',
    },
    pictures: {
      title: 'Gallery image',
      description: '≥ 1 Gallery image is available online shop',
    },
    menu_uploaded: {
      title: 'Menu Uploaded',
      description: '≥ 1 menu is available',
    },
    opening_hours: {
      title: 'Opening hours',
      description: '≥ 1 opening hours added',
    },
    address: {
      title: 'Restaurant address',
      description: 'Street, city, plz entered',
    },
    upsell_items: {
      title: 'Upsell items',
      description: '≥ 3 upsell items have been selected',
    },
    master_promo_code: {
      title: 'Master Promo Code',
      description: 'Master promo code is available',
    },
    flyers_ordered: {
      title: 'Flyers ordered',
      description: '≥ 1 Flyer package ordered',
    },
    test_orders: {
      title: 'Test order sent',
      description: '≥ 1 Test order has been made',
    },
    stripe_billing: {
      title: 'Stripe Account',
      description: 'Stripe account connected',
    },
    storefront: {
      title: 'Storefront created and domain connected',
      description: 'Only if restaurant wants to use our website',
    },
    google_business: {
      title: 'Google Business',
      description: 'Google Business connected',
    },
  },
};
