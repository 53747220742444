export default {
  toasts: {
    failed_to_delete_website: 'Löschen der Website fehlgeschlagen',
    website_deleted: 'Website wurde gelöscht',
  },
  delete_model: {
    title: 'Sind Sie sicher, dass Sie die Website löschen möchten?',
    confirm: 'Löschen',
    cancel: 'Abbrechen',
  },
  buttons: {
    open_editor: 'Editor öffnen',
    delete_site: 'Löschen',
  },
};
