import { ONBOARDING_GUIDE_STEPS } from '../../../../../services/exports/Constants';

export default {
  titles: {
    [ONBOARDING_GUIDE_STEPS.WhatsApp]: 'Turn on Whatsapp notifications',
    [ONBOARDING_GUIDE_STEPS.Voice]: 'Automated calls about pending orders',
    [ONBOARDING_GUIDE_STEPS.OpenTimes]: 'Open times',
    [ONBOARDING_GUIDE_STEPS.Location]: 'Location',
    [ONBOARDING_GUIDE_STEPS.PickupTime]: 'Pickup time',
    [ONBOARDING_GUIDE_STEPS.DeliveryTime]: 'Delivery time',
    [ONBOARDING_GUIDE_STEPS.StaffCredentials]: 'Staff credentials',
    [ONBOARDING_GUIDE_STEPS.ImportMenu]: 'Import menu',
    [ONBOARDING_GUIDE_STEPS.Favourites]: 'Favourites',
    [ONBOARDING_GUIDE_STEPS.SoldOutItems]: 'Sold out items',
    [ONBOARDING_GUIDE_STEPS.UpsellItems]: 'Upsell items',
    [ONBOARDING_GUIDE_STEPS.PromoCodes]: 'Promo codes',
    [ONBOARDING_GUIDE_STEPS.FreeEligibleItems]: 'Free eligible items',
    [ONBOARDING_GUIDE_STEPS.Gallery]: 'Gallery',
    [ONBOARDING_GUIDE_STEPS.LogoAndPrimaryColor]: 'Add restaurant logo and primary color',
    [ONBOARDING_GUIDE_STEPS.OrderFlyers]: 'Order flyers',
    [ONBOARDING_GUIDE_STEPS.CreateStorefront]: 'Create storefront',
    [ONBOARDING_GUIDE_STEPS.EnableLiveOrders]: 'Enable live orders',
    [ONBOARDING_GUIDE_STEPS.GoogleBusiness]: 'Enable google business',
  },
  descriptions: {
    [ONBOARDING_GUIDE_STEPS.WhatsApp]: 'Check if owner/ contact person has Whatsapp',
    [ONBOARDING_GUIDE_STEPS.Voice]: 'Check if restaurant number is correct to receive automated calls to not miss orders',
    [ONBOARDING_GUIDE_STEPS.OpenTimes]: 'Set up restaurant open times',
    [ONBOARDING_GUIDE_STEPS.Location]: 'Set up restaurant location',
    [ONBOARDING_GUIDE_STEPS.PickupTime]: 'Set up pickup time settings',
    [ONBOARDING_GUIDE_STEPS.DeliveryTime]: 'Set up delivery time settings',
    [ONBOARDING_GUIDE_STEPS.StaffCredentials]: 'Set up staff credentials',
    [ONBOARDING_GUIDE_STEPS.ImportMenu]: 'Import menu from 3rd party provider',
    [ONBOARDING_GUIDE_STEPS.Favourites]: 'Mark 4 items as favorites. Ask the restaurant which are the 4 most popular dishes',
    [ONBOARDING_GUIDE_STEPS.SoldOutItems]: 'Set up sold out items behavior',
    [ONBOARDING_GUIDE_STEPS.UpsellItems]: 'Choose 5 items e.g. sides, sauces, desserts as upsell items. List them from top to bottom starting with most popular',
    [ONBOARDING_GUIDE_STEPS.PromoCodes]: 'Set up promo codes',
    [ONBOARDING_GUIDE_STEPS.FreeEligibleItems]: 'Select at least 1 menu item as a free item. The more the better. Suggest to the owner high margin items like sides or desserts as free rewards',
    [ONBOARDING_GUIDE_STEPS.Gallery]: 'Update gallery',
    [ONBOARDING_GUIDE_STEPS.LogoAndPrimaryColor]: 'Get the logo from the restaurant or the restaurant page on Lieferando. And save the logo from there. Pick the colour that is most present in the restaurant logo',
    [ONBOARDING_GUIDE_STEPS.OrderFlyers]: 'Edit flyer design and create order',
    [ONBOARDING_GUIDE_STEPS.CreateStorefront]: 'Create storefront and edit the website if necessary',
    [ONBOARDING_GUIDE_STEPS.EnableLiveOrders]: 'Turn on live orders (”!only on launch date!”)',
    [ONBOARDING_GUIDE_STEPS.GoogleBusiness]: 'Connect Google Business account',
  },
  buttons: {
    mark_as_complete: 'Mark as complete',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
  },
};
